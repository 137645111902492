export const getFeatureFlags = () => ({});

export const getSteps = steps => [
  [
    {
      name: steps.offers,
      pathname: '/offers',
    },
  ],
];

export const isSupportSingleLanguage = (user, isUserLoaded) => {
  // Get user token
  if (isUserLoaded) {
    // eslint-disable-next-line prefer-destructuring
    const countriesSupportSingleLanguage = window.countriesSupportSingleLanguage;
    return countriesSupportSingleLanguage.includes(user.CountryId);
  }
};

export const getbreadCrumbSteps = (localization, language) => {
  const breadcrumbSteps = [];
  if (localization) {
    const tabs = getSteps(localization.Steps);
    tabs.map(tab => breadcrumbSteps.push(tab));
  }

  const breadcrumbProps = {
    stepsArray: breadcrumbSteps,
    validationElementName: '#user-form',
    reverse: language !== 'en',
  };

  return breadcrumbProps;
};
