import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Flex, Spinner, EmptyState } from '@vezeeta/web-components';
import NotAutherizedView from './NotAuthorizedComponents';
import { getRoleBasedAccess, USER_PRIVILEGES } from './RoleBasedAccess';

const UseRoute = ({ ...props }) => {
  const { path, render, localization, isUserLoaded, isAvailableInCurrentCountry } = props;
  const onRenderPropsChanges = routeProps => {
    let isAccessed = true;

    const roledBasedAccessCatalogue = getRoleBasedAccess();
    switch (routeProps.location.pathname) {
      case '/offers':
        isAccessed =
          roledBasedAccessCatalogue.offers_view.canReadAndWrite === USER_PRIVILEGES.AUTHORIZED;
        break;

      default:
        break;
    }
    if (isUserLoaded && localization) {
      const emptyState = {
        image: `${process.env.REACT_APP_CDN_URL}/assets/empty.svg`,
        text: localization && localization.notAutherized,
      };

      if (isAvailableInCurrentCountry) {
        if (isAccessed) return render(routeProps);
        return <NotAutherizedView localization={localization} />;
      }
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '500px',
          }}
        >
          <EmptyState imageWidth={225} {...emptyState} />
        </div>
      );
    }
    return (
      <Flex justifyContent="center" alignItems="center" py={10}>
        <Spinner />
      </Flex>
    );
  };
  return <Route path={path} render={onRenderPropsChanges} />;
};

UseRoute.propTypes = {
  path: PropTypes.string,
  render: PropTypes.func,
  localization: PropTypes.object,
  isUserLoaded: PropTypes.bool,
};
export default UseRoute;
