import styled, { css } from 'styled-components';
import { Flex } from '@vezeeta/web-components';

export const LoadingContainer = styled(Flex)`
  height: calc(100vh - 60px);
`;

export const extendContentContainer = css`
  ${props => props.theme.media.tablet`
    padding: 0px;
  `};
  ${props => props.theme.media.phone`
    padding: 0px;
  `};
`;

export const MainContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${props => props.theme.media.phone`
    flex-direction: column;
  `};

  ${props => props.theme.media.tablet`
    flex-direction: column;
  `};
`;

export const OffersContainerWrapper = styled.div`
  width: 33.333%;
  ${props => props.theme.media.phone`
    width: 100%;
  `};

  ${props => props.theme.media.tablet`
    width: 100%;
  `};
`;

export const ServiceFormContainerWrapper = styled.div`
  width: 66.666%;
  padding: 0px 15px;
  ${props => props.theme.media.phone`
    width: 100%;
    padding: 0px;
  `};

  ${props => props.theme.media.tablet`
    width: 100%;
    padding: 0px;
  `};
`;
