import React, { useEffect } from 'react';
import { FeatureFlagContainer, SetGlobalStyle } from '@vezeeta/web-components';
import EnterpriseLayout from '@vezeeta/enterprise-layout';
import PropTypes from 'prop-types';
import { language } from '@vezeeta/enterprise-store/lib/types/language';
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import '@vezeeta/enterprise-modals/lib/Modal.css';
import { MixpanelConsumer } from 'react-mixpanel';
import { connect } from 'react-redux';
import '@vezeeta/web-components/lib/shared/Utils.css';
import RoleBasedRoute from 'app/RoleBasedAccess/RoleBasedRoute';
import { CodeRedeemContainer, ServiceFormContainer } from 'app/Routes';
import { withTranslation } from 'app/withTranslation/withTranslation';
import { getFeatureFlags, getbreadCrumbSteps, isSupportSingleLanguage } from './App.helper';
import { extendContentContainer } from './App.styles';
import './App.css';

const App = ({ ...props }) => {
  useEffect(() => {
    SetGlobalStyle();
  }, []);

  const { user, isUserLoaded, localization } = props;
  const supportSingleLanguage = isSupportSingleLanguage(user, isUserLoaded);
  return (
    <FeatureFlagContainer.Provider value={getFeatureFlags()}>
      <EnterpriseLayout
        breadcrumbProps={getbreadCrumbSteps(localization, language)}
        supportSingleLanguage={supportSingleLanguage}
        activeTab="Offers"
        extendContentContainer={extendContentContainer}
      >
        <MixpanelConsumer>
          {mixpanel => (
            <Router>
              <Switch>
                <RoleBasedRoute
                  path="/offers"
                  exact
                  isUserLoaded={isUserLoaded}
                  localization={localization}
                  isAvailableInCurrentCountry={window.availableCountries.includes(user.CountryId)}
                  render={routeProps => (
                    <ServiceFormContainer
                      {...routeProps}
                      language={language}
                      localization={localization.offersManagement}
                      mixpanel={mixpanel}
                    />
                  )}
                />
                <RoleBasedRoute
                  path="/bookings"
                  isUserLoaded={isUserLoaded}
                  localization={localization}
                  isAvailableInCurrentCountry={window.availableCountries.includes(user.CountryId)}
                  render={routeProps => (
                    <CodeRedeemContainer
                      {...routeProps}
                      language={language}
                      localization={localization.offersManagement}
                      mixpanel={mixpanel}
                    />
                  )}
                />
                <Redirect to="/offers" />
              </Switch>
            </Router>
          )}
        </MixpanelConsumer>
      </EnterpriseLayout>
    </FeatureFlagContainer.Provider>
  );
};

App.propTypes = {
  isUserLoaded: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  localization: PropTypes.object,
};

const mapStateToProps = state => ({
  isUserLoaded: state.user.loaded,
  user: state.user.user,
});

export default withTranslation(connect(mapStateToProps)(App));
