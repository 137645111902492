/* eslint-disable import/prefer-default-export */
import Loadable from 'react-loadable';
import { Loading } from '@vezeeta/web-components';

export const ServiceFormContainer = Loadable({
  loader: () => import('../views/serviceForm/ServiceFormContainer'),
  loading: Loading,
});

export const CodeRedeemContainer = Loadable({
  loader: () => import('../views/codeRedeem/CodeRedeemContainer'),
  loading: Loading,
});
